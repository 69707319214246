import React, {useEffect, useState} from 'react';
import {InputGroup} from "react-bootstrap";
import {Switch} from "@mui/material";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import CustomDateRangePicker from "../../components/CustomDateRangePicker/CustomDateRangePicker";
import CustomDailyRangePicker from "../../components/CustomDailyRangePicker/CustomDailyRangePicker";
import GetZones from "../../queries/Nutitee/GetZones";
import PedestrianStatistics from "../../components/PedestrianStatistics/PedestrianStatistics";
import NutiteeGlobals from "./NutiteeGlobals";
import PedestrianGlobals from "./PedestrianGlobals";
import Info from "./Info";
import Contact from "./Contact";
import NutiteeAdvanced from "./NutiteeAdvanced";
import NutiteeMap from "./NutiteeMap";
import DeployedDevicesMap from "./DeployedDevicesMap/DeployedDevicesMap";
import AirlyWidget from "../../components/AirlyWidget/AirlyWidget";
import ContactQuery from "../../queries/Devices/Contact";
export default function Nutitee() {
    const {t} = useTranslation();

    const devices = [
        {
            "id": 27,
            "uuid": "d8632d91-fb90-4327-8855-ed70b2603bc6",
            "name": "P\u00f5lva 1",
            "airly_id": 0,
            "speed_trigger": 55,
            "modification": "M",
            "radars": 0,
            "coordinates": "58.052556;27.043306",
            "camera_a_id": null,
            "camera_b_id": null,
            "has_vms": 0,
            "created_at": "2023-12-20T16:02:07.000000Z",
            "updated_at": "2023-12-20T16:02:07.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 15,
                    "device_id": 27,
                    "api_key_id": 8,
                    "core_id": "A",
                    "created_at": "2023-12-20T16:11:11.000000Z",
                    "updated_at": "2024-10-24T06:43:22.000000Z"
                },
                {
                    "id": 16,
                    "device_id": 27,
                    "api_key_id": 8,
                    "core_id": "B",
                    "created_at": "2023-12-20T16:11:11.000000Z",
                    "updated_at": "2024-10-24T06:43:22.000000Z"
                }
            ]
        },
        {
            "id": 28,
            "uuid": "9fad0cc7-1692-4917-ba4a-018e8ce76a4c",
            "name": "P\u00f5lva 2",
            "airly_id": 0,
            "speed_trigger": 55,
            "modification": "M",
            "radars": 1,
            "coordinates": "58.052583;27.044083",
            "camera_a_id": 9,
            "camera_b_id": 10,
            "has_vms": 1,
            "created_at": "2023-12-20T16:02:43.000000Z",
            "updated_at": "2023-12-29T09:54:54.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 17,
                    "device_id": 28,
                    "api_key_id": 9,
                    "core_id": "A",
                    "created_at": "2023-12-20T16:16:25.000000Z",
                    "updated_at": "2024-10-24T06:43:18.000000Z"
                },
                {
                    "id": 18,
                    "device_id": 28,
                    "api_key_id": 9,
                    "core_id": "B",
                    "created_at": "2023-12-20T16:16:25.000000Z",
                    "updated_at": "2024-10-24T06:43:18.000000Z"
                }
            ]
        },
        {
            "id": 29,
            "uuid": "e45026ce-b026-4366-86d6-6e19287b12cf",
            "name": "P\u00f5lva 3",
            "airly_id": 0,
            "speed_trigger": 55,
            "modification": "M",
            "radars": 0,
            "coordinates": "58.052639;27.046028",
            "camera_a_id": null,
            "camera_b_id": null,
            "has_vms": 0,
            "created_at": "2023-12-20T16:05:28.000000Z",
            "updated_at": "2023-12-20T16:05:28.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 19,
                    "device_id": 29,
                    "api_key_id": 10,
                    "core_id": "A",
                    "created_at": "2023-12-20T16:26:11.000000Z",
                    "updated_at": "2024-10-24T06:43:21.000000Z"
                },
                {
                    "id": 20,
                    "device_id": 29,
                    "api_key_id": 10,
                    "core_id": "B",
                    "created_at": "2023-12-20T16:26:11.000000Z",
                    "updated_at": "2024-10-24T06:43:21.000000Z"
                }
            ]
        },
        {
            "id": 30,
            "uuid": "25e908aa-e169-4f3d-a65c-7f3ce17e041e",
            "name": "P\u00f5lva 4",
            "airly_id": 0,
            "speed_trigger": 55,
            "modification": "M",
            "radars": 0,
            "coordinates": "58.052639;27.046833",
            "camera_a_id": null,
            "camera_b_id": null,
            "has_vms": 0,
            "created_at": "2023-12-20T16:06:07.000000Z",
            "updated_at": "2023-12-20T16:06:07.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 21,
                    "device_id": 30,
                    "api_key_id": 11,
                    "core_id": "A",
                    "created_at": "2023-12-20T16:42:54.000000Z",
                    "updated_at": "2024-10-24T06:43:16.000000Z"
                },
                {
                    "id": 22,
                    "device_id": 30,
                    "api_key_id": 11,
                    "core_id": "B",
                    "created_at": "2023-12-20T16:42:54.000000Z",
                    "updated_at": "2024-10-24T06:43:16.000000Z"
                }
            ]
        },
        {
            "id": 31,
            "uuid": "285d0ff9-66b2-48d8-b274-19deaa924244",
            "name": "P\u00f5lva 5",
            "airly_id": 0,
            "speed_trigger": 50,
            "modification": "M",
            "radars": 2,
            "coordinates": "58.052917;27.050222",
            "camera_a_id": 7,
            "camera_b_id": 8,
            "has_vms": 0,
            "created_at": "2023-12-20T16:06:51.000000Z",
            "updated_at": "2024-01-08T16:27:44.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 23,
                    "device_id": 31,
                    "api_key_id": 12,
                    "core_id": "A",
                    "created_at": "2023-12-20T16:50:18.000000Z",
                    "updated_at": "2024-10-24T06:43:14.000000Z"
                },
                {
                    "id": 24,
                    "device_id": 31,
                    "api_key_id": 12,
                    "core_id": "B",
                    "created_at": "2023-12-20T16:50:18.000000Z",
                    "updated_at": "2024-10-24T06:43:14.000000Z"
                }
            ]
        },
        {
            "id": 32,
            "uuid": "1d8f6fe9-d21e-49f1-ac75-25437d9b2d46",
            "name": "P\u00f5lva 6",
            "airly_id": 0,
            "speed_trigger": 55,
            "modification": "M",
            "radars": 0,
            "coordinates": "58.052944;27.050389",
            "camera_a_id": null,
            "camera_b_id": null,
            "has_vms": 0,
            "created_at": "2023-12-20T16:07:18.000000Z",
            "updated_at": "2023-12-20T16:07:18.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 25,
                    "device_id": 32,
                    "api_key_id": 13,
                    "core_id": "A",
                    "created_at": "2023-12-20T16:58:12.000000Z",
                    "updated_at": "2024-10-24T06:43:21.000000Z"
                },
                {
                    "id": 26,
                    "device_id": 32,
                    "api_key_id": 13,
                    "core_id": "B",
                    "created_at": "2023-12-20T16:58:12.000000Z",
                    "updated_at": "2024-10-24T06:43:21.000000Z"
                }
            ]
        },
        {
            "id": 33,
            "uuid": "75c44d34-e262-4045-97f9-bee5c63cc85b",
            "name": "P\u00f5lva 7",
            "airly_id": 0,
            "speed_trigger": 55,
            "modification": "M",
            "radars": 0,
            "coordinates": "58.053611;27.054917",
            "camera_a_id": null,
            "camera_b_id": null,
            "has_vms": 0,
            "created_at": "2023-12-20T16:07:41.000000Z",
            "updated_at": "2024-01-09T07:38:22.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 27,
                    "device_id": 33,
                    "api_key_id": 14,
                    "core_id": "A",
                    "created_at": "2023-12-20T17:07:40.000000Z",
                    "updated_at": "2024-10-24T06:43:24.000000Z"
                },
                {
                    "id": 28,
                    "device_id": 33,
                    "api_key_id": 14,
                    "core_id": "B",
                    "created_at": "2023-12-20T17:07:40.000000Z",
                    "updated_at": "2024-10-24T06:43:24.000000Z"
                }
            ]
        },
        {
            "id": 34,
            "uuid": "1a303241-1afa-42cf-875a-7f36ebbca498",
            "name": "P\u00f5lva 8",
            "airly_id": 64042,
            "speed_trigger": 55,
            "modification": "M",
            "radars": 0,
            "coordinates": "58.054111;27.055972",
            "camera_a_id": null,
            "camera_b_id": null,
            "has_vms": 0,
            "created_at": "2023-12-20T16:08:05.000000Z",
            "updated_at": "2024-10-01T06:13:05.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 29,
                    "device_id": 34,
                    "api_key_id": 15,
                    "core_id": "A",
                    "created_at": "2023-12-20T17:13:33.000000Z",
                    "updated_at": "2024-10-24T06:43:16.000000Z"
                },
                {
                    "id": 30,
                    "device_id": 34,
                    "api_key_id": 15,
                    "core_id": "B",
                    "created_at": "2023-12-20T17:13:33.000000Z",
                    "updated_at": "2024-10-24T06:43:16.000000Z"
                }
            ]
        },
        {
            "id": 35,
            "uuid": "58e71f2d-752c-4737-b1fb-68dbb2505b30",
            "name": "P\u00f5lva 9",
            "airly_id": 0,
            "speed_trigger": 40,
            "modification": "M",
            "radars": 1,
            "coordinates": "58.054417;27.057139",
            "camera_a_id": null,
            "camera_b_id": null,
            "has_vms": 0,
            "created_at": "2023-12-20T16:08:25.000000Z",
            "updated_at": "2024-01-08T15:44:41.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 31,
                    "device_id": 35,
                    "api_key_id": 16,
                    "core_id": "A",
                    "created_at": "2023-12-20T17:26:02.000000Z",
                    "updated_at": "2024-10-24T06:43:23.000000Z"
                },
                {
                    "id": 32,
                    "device_id": 35,
                    "api_key_id": 16,
                    "core_id": "B",
                    "created_at": "2023-12-20T17:26:02.000000Z",
                    "updated_at": "2024-10-24T06:43:23.000000Z"
                }
            ]
        },
        {
            "id": 26,
            "uuid": "abea6b8f-3e3c-4eec-9d6e-65c21ac747ec",
            "name": "P\u00f5lva 10",
            "airly_id": 0,
            "speed_trigger": 55,
            "modification": "M",
            "radars": 2,
            "coordinates": "58.05480731471403;27.05864148845909",
            "camera_a_id": 5,
            "camera_b_id": 6,
            "has_vms": 0,
            "created_at": "2023-12-12T17:59:27.000000Z",
            "updated_at": "2023-12-12T17:59:27.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 13,
                    "device_id": 26,
                    "api_key_id": 5,
                    "core_id": "A",
                    "created_at": "2023-12-19T16:35:26.000000Z",
                    "updated_at": "2024-10-24T06:43:20.000000Z"
                },
                {
                    "id": 14,
                    "device_id": 26,
                    "api_key_id": 5,
                    "core_id": "B",
                    "created_at": "2023-12-19T16:35:26.000000Z",
                    "updated_at": "2024-10-24T06:43:20.000000Z"
                }
            ]
        },
        {
            "id": 22,
            "uuid": "e5b1b92c-e160-45e8-b8df-393a7e75b525",
            "name": "P\u00f5lva 11",
            "airly_id": 0,
            "speed_trigger": 55,
            "modification": "M",
            "radars": 2,
            "coordinates": "58.05846473984606;27.065532705941077",
            "camera_a_id": 1,
            "camera_b_id": 4,
            "has_vms": 0,
            "created_at": "2023-11-27T19:10:45.000000Z",
            "updated_at": "2023-11-27T19:10:45.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 5,
                    "device_id": 22,
                    "api_key_id": 3,
                    "core_id": "A",
                    "created_at": "2023-11-29T10:00:54.000000Z",
                    "updated_at": "2024-10-24T06:43:21.000000Z"
                },
                {
                    "id": 6,
                    "device_id": 22,
                    "api_key_id": 3,
                    "core_id": "B",
                    "created_at": "2023-11-29T10:00:54.000000Z",
                    "updated_at": "2024-10-24T06:43:21.000000Z"
                }
            ]
        },
        {
            "id": 24,
            "uuid": "181f5cca-df01-4706-9a95-eb7b19946fb7",
            "name": "P\u00f5lva 12",
            "airly_id": 0,
            "speed_trigger": 55,
            "modification": "M",
            "radars": 1,
            "coordinates": "58.06355709068779;27.070156576532767",
            "camera_a_id": null,
            "camera_b_id": null,
            "has_vms": 0,
            "created_at": "2023-12-12T17:58:04.000000Z",
            "updated_at": "2023-12-12T17:58:04.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 9,
                    "device_id": 24,
                    "api_key_id": 6,
                    "core_id": "A",
                    "created_at": "2023-12-12T18:11:57.000000Z",
                    "updated_at": "2024-10-24T06:43:23.000000Z"
                },
                {
                    "id": 10,
                    "device_id": 24,
                    "api_key_id": 6,
                    "core_id": "B",
                    "created_at": "2023-12-12T18:11:57.000000Z",
                    "updated_at": "2024-10-24T06:43:23.000000Z"
                }
            ]
        },
        {
            "id": 25,
            "uuid": "8cc79edb-9e35-4b14-ac22-f86055ccb3a1",
            "name": "P\u00f5lva 13",
            "airly_id": 0,
            "speed_trigger": 55,
            "modification": "M",
            "radars": 0,
            "coordinates": "58.064253145406184;27.070802402350058",
            "camera_a_id": null,
            "camera_b_id": null,
            "has_vms": 0,
            "created_at": "2023-12-12T17:58:37.000000Z",
            "updated_at": "2023-12-12T17:58:37.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 11,
                    "device_id": 25,
                    "api_key_id": 7,
                    "core_id": "A",
                    "created_at": "2023-12-12T18:13:03.000000Z",
                    "updated_at": "2024-10-24T06:43:14.000000Z"
                },
                {
                    "id": 12,
                    "device_id": 25,
                    "api_key_id": 7,
                    "core_id": "B",
                    "created_at": "2023-12-12T18:13:03.000000Z",
                    "updated_at": "2024-10-24T06:43:14.000000Z"
                }
            ]
        },
        {
            "id": 23,
            "uuid": "a74a390e-0c60-46fb-9f20-e119a6d28c54",
            "name": "P\u00f5lva 14",
            "airly_id": 0,
            "speed_trigger": 55,
            "modification": "M",
            "radars": 1,
            "coordinates": "58.06566561108105;27.072055479609467",
            "camera_a_id": 2,
            "camera_b_id": 3,
            "has_vms": 1,
            "created_at": "2023-11-27T19:11:33.000000Z",
            "updated_at": "2023-11-27T19:11:33.000000Z",
            "device_notifications_count": 0,
            "device_heartbeats": [
                {
                    "id": 7,
                    "device_id": 23,
                    "api_key_id": 4,
                    "core_id": "A",
                    "created_at": "2023-11-29T10:12:18.000000Z",
                    "updated_at": "2024-10-24T06:43:17.000000Z"
                },
                {
                    "id": 8,
                    "device_id": 23,
                    "api_key_id": 4,
                    "core_id": "B",
                    "created_at": "2023-11-29T10:12:18.000000Z",
                    "updated_at": "2024-10-24T06:43:17.000000Z"
                }

            ]
  }
    ]
;

    const [isDailyView, setIsDailyView] = useState(false);
    const [selectedTimeMode, setSelectedTimeMode] = useState(0);
    const [startDate, setStartDate] = useState(moment().startOf('week').add(3, 'hours').toDate());
    const [endDate, setEndDate] = useState(moment().endOf('week').add(3, 'hours').toDate());
    const [Points, setPoints] = useState([]);


    const setStartAndEndDate = (startDateInput, endDateInput) => {
        setStartDate(() => startDateInput);
        setEndDate(() => endDateInput);
    }

    const handleSetSelectedTimeMode = (mode) => {
        setSelectedTimeMode(mode);
    }

    const toggleDailyView = () => {

        setIsDailyView((prevState) => {
            if (prevState) {
                // SET TO ADVANCED VIEW
                setSelectedTimeMode(0);
                setStartDate(moment().startOf('week').toDate());
                setEndDate(moment().endOf('week').toDate());
            } else {
                // SET TO DAILY VIEW
                setStartDate(moment().startOf('day').toDate());
                setEndDate(moment().endOf('day').toDate());
            }

            return (!prevState);
        });
    }

    const prevDay = () => {
        setStartDate((prev) => moment(prev).subtract(1, 'day').toDate());
        setEndDate((prev) => moment(prev).subtract(1, 'day').toDate());

    }

    const nextDay = () => {
        setStartDate((prev) => moment(prev).add(1, 'day').toDate());
        setEndDate((prev) => moment(prev).add(1, 'day').toDate());
    }

    return (
        <>
<Info opened={false}/>
<PedestrianGlobals startDate={startDate} endDate={endDate} opened={true}/>
           
<>
<AirlyWidget airlyID={64042} opened={false}/>                
            </>
            <>
                <NutiteeGlobals startDate={startDate} endDate={endDate} opened={true}/>
            </>
		<DeployedDevicesMap devices={devices} />
            <>

               <NutiteeAdvanced startDate={startDate} endDate={endDate} opened={false} spc={[2,5]}/>
                <NutiteeAdvanced startDate={startDate} endDate={endDate} opened={false} spc={[5,10]}/>
                <NutiteeAdvanced startDate={startDate} endDate={endDate} opened={false} spc={[10,11]}/>
                <NutiteeAdvanced startDate={startDate} endDate={endDate} opened={false} spc={[11,14]}/>
<Contact />                
            </>
        </>
    );
}
